import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-logo',
  standalone: true,
  template: `
    <div class="fixed inset-0 flex items-center justify-center animate-pulse">
      <svg
        width="95"
        height="90"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.3364 7.57126C12.3364 7.17995 12.0188 6.82422 11.5954 6.82422H5.72593H1.42075C1.03257 6.82422 0.679688 7.14438 0.679688 7.57126L0.691241 16.6475C0.691241 17.0388 1.00884 17.3945 1.4323 17.3945H17.4532C17.8414 17.3945 18.1943 17.0744 18.1943 16.6475V13.6949C18.1943 13.3036 17.8767 12.9479 17.4532 12.9479H6.51382C6.12565 12.9479 5.77277 12.6277 5.77277 12.2009C5.77277 11.8096 6.09036 11.4538 6.51382 11.4538H11.5954C11.9835 11.4538 12.3364 11.1337 12.3364 10.7068V7.57126Z"
          fill="#231F1F" />
        <path
          d="M5.03201 5.51357H1.3973C0.973837 5.51357 0.65625 5.15784 0.65625 4.76653V1.10251C0.65625 0.675629 1.00913 0.355469 1.3973 0.355469H5.03201C5.45547 0.355469 5.77306 0.711202 5.77306 1.10251V4.76653C5.77306 5.19341 5.45547 5.51357 5.03201 5.51357Z"
          fill="#231F1F" />
      </svg>
    </div>
  `,
  styles: ``,
})
export class LoadingLogoComponent {}
